import React from 'react';
import classNames from 'classnames';
import { formatPrice } from '../../helpers/formatterHelper';
import { ProductNode } from '../../interfaces/contentful';
import { priceStyle } from './PriceStyle';
import { makeStyles } from '@material-ui/core/styles';

interface PriceProps {
  product: ProductNode;
}

const useStyles = makeStyles(priceStyle);

export const Price: React.FC<PriceProps> = ({ product }) => {
  const classes = useStyles();

  if (process.env.GATSBY_PREORDERING_STARTED !== 'true') {
    return null;
  }

  return (
    <div className={classes.priceContainer}>
      {product.discountedPrice && (
        <>
          <span className={classNames(classes.price, classes.priceOld)}>
            &nbsp;
            {formatPrice(product.price)}
          </span>
          <span className={classNames(classes.price, classes.priceNew)}>
            &nbsp;
            {formatPrice(product.discountedPrice)}
          </span>
        </>
      )}
      {!product.discountedPrice && (
        <span className={classes.price}>
          &nbsp;
          {formatPrice(product.price)}
        </span>
      )}
    </div>
  );
};
